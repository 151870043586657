import { NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";

// import { HttpInterceptorService } from "@app/core/services/http-interceptor.service";

@NgModule({
  imports: [HttpClientModule],
  // providers: [
  //   {
  //     provide: HTTP_INTERCEPTORS,
  //     useClass: HttpInterceptorService,
  //     multi: true,
  //   },
  // ],
})
export class CoreModule {}
