<div class="app-menu navbar-menu">
  <div class="navbar-brand-box">
    <a routerLink="/" class="logo logo-dark">
      <span class="logo-sm">
        <img src="assets/images/cufi-seal.svg" alt="" height="30">
      </span>

      <span class="logo-lg">
        <img src="assets/images/cufi-dark.svg" alt="" height="17">
      </span>
    </a>

    <a routerLink="/" class="logo logo-light">
      <span class="logo-sm">
        <img src="assets/images/cufi-seal.svg" alt="" height="30">
      </span>

      <span class="logo-lg">
        <img src="assets/images/cufi-light.svg" alt="" height="45">
      </span>
    </a>

    <button type="button" class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover">
      <i class="ri-record-circle-line"></i>
    </button>
  </div>



  <div id="scrollbar">
    <div class="container-fluid">
      <div id="two-column-menu"></div>

      <ul class="navbar-nav" id="navbar-nav">
        @for (item of menuItems; track $index) {
          @if (item.isTitle) {
            <li class="menu-title">
              <span data-key="t-menu">{{ item.label }}</span>
            </li>
          } @else {
            <li class="nav-item">
              @if (hasItems(item)) {
                <a href="javascript:void(0);" class="is-parent nav-link menu-link" [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}" data-bs-toggle="collapse" aria-expanded="false" (click)="toggleItem($event)">
                  <i class="{{ item.icon }} icon nav-icon" class=""></i>

                  <span class=""> {{ item.label  }} </span>

                  @if (item.badge) {
                    <span class="badge badge-pill {{item.badge.variant}}">{{item.badge.text }}</span>
                  }
                </a>

                <div class="collapse menu-dropdown" id="sidebarDashboards">
                  <ul class="nav nav-sm flex-column" aria-expanded="false">
                    @for (subitem of item.subItems; track $index) {
                      <li class="nav-item">

                      @if (hasItems(subitem)) {
                        <a class="nav-link" href="javascript:void(0);" [attr.data-parent]="subitem.parentId" data-bs-toggle="collapse" aria-expanded="false" (click)="toggleSubItem($event)">
                          @if (subitem.icon) {
                            <i class="bx {{ subitem.icon }} icon nav-icon" class=""></i>
                          }{{ subitem.label }}
                        </a>

                        <div class="collapse menu-dropdown" [ngClass]="{'mega-dropdown-menu':subitem.label == 'Bootstrap UI'}">
                          @if (subitem.label == "Bootstrap UI" && subitem.subItems.length > 13) {
                            <div class="row">
                              @for (subSubitem of subitem.subItems; track $index) {
                                @if($index % 2 ==0 ){
                                  <div class="col-lg-4">
                                    <ul class="nav nav-sm flex-column" aria-expanded="false">
                                      <li class="nav-item">
                                        <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link" class="nav-link" (click)="updateActive($event)">{{ subSubitem.label  }}</a>
                                      </li>
                                    </ul>
                                  </div>
                                } @else {
                                <div class="col-lg-4">
                                  <ul class="nav nav-sm flex-column" aria-expanded="false">
                                    <li class="nav-item">
                                      <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link" class="nav-link" (click)="updateActive($event)">{{ subSubitem.label  }}</a>
                                    </li>
                                  </ul>
                                </div>
                                }
                              }
                            </div>
                          } @else {
                            <ul class="nav nav-sm flex-column" aria-expanded="false">
                              @for (subSubitem of subitem.subItems; track $index) {
                                <li class="nav-item">
                                  @if (subSubitem.subItems) {
                                    <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link" data-bs-toggle="collapse" aria-expanded="false" class="nav-link" (click)="updateActive($event)">{{ subSubitem.label  }}</a>

                                    <div class="collapse menu-dropdown">
                                      <ul class="nav nav-sm flex-column" aria-expanded="false">
                                        @for (subChilditem of subSubitem.subItems; track $index) {
                                          <li class="nav-item">
                                            @if (subChilditem.subItems) {
                                            <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subChilditem.link" data-bs-toggle="collapse" aria-expanded="false" class="nav-link" (click)="updateActive($event)">
                                              {{ subChilditem.label  }}
                                            </a>

                                            <div class="collapse menu-dropdown">
                                              <ul class="nav nav-sm flex-column" aria-expanded="false">
                                                @for (Childitem of subChilditem.subItems; track $index) {
                                                  <li class="nav-item">
                                                    <a [attr.data-parent]="subSubitem.parentId" [routerLink]="Childitem.link" class="nav-link" (click)="updateActive($event)">{{ Childitem.label  }}</a>
                                                  </li>
                                                }
                                              </ul>
                                            </div>
                                            } @else {
                                              <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subChilditem.link" class="nav-link" (click)="updateActive($event)">{{ subChilditem.label  }}</a>
                                            }
                                          </li>
                                        }
                                      </ul>
                                    </div>
                                  } @else {
                                    <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link" class="nav-link" (click)="updateActive($event)">{{ subSubitem.label  }}</a>
                                  }
                                </li>
                              }
                            </ul>
                          }
                        </div>
                      }@else {
                      <a [routerLink]="subitem.link" class="nav-link" [attr.data-parent]="subitem.parentId" (click)="updateActive($event)">
                        @if (subitem.icon) {<i class="bx {{ subitem.icon }} icon nav-icon" class=""></i>}{{ subitem.label }}
                      </a>
                      }
                    </li>
                    }
                  </ul>
                </div>
              }@else {
                <a [routerLink]="item.link" class="side-nav-link-ref nav-link menu-link">
                  @if (item.icon) {
                    <i class="bx {{ item.icon }} icon nav-icon" class=""></i>
                  }

                  <span class=""> {{ item.label  }}</span>

                  @if (item.badge) {
                    <span class="badge {{item.badge.variant}} float-end">{{item.badge.text }}</span>
                  }
                </a>
              }
            </li>
          }
        }
      </ul>
    </div>
  </div>
</div>
