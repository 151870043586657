<button (click)="topFunction()" class="btn btn-dark btn-icon" id="back-to-top">
  <i class="bi bi-caret-up fs-3xl"></i>
</button>



<div id="preloader">
  <div id="status">
    <div class="spinner-border text-primary avatar-sm" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</div>



<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<div class="offcanvas-backdrop fade backdrop2 hidden" id="backdrop" (click)="closeoffcanvas()"></div>
