import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'Menu',
        isTitle: true
    },
    {
        id: 2,
        label: 'Dashboard',
        icon: 'bi bi-speedometer2',
        link: '/'
    },
    {
        id: 6,
        label: 'Forms',
        link: '/forms',
        icon: 'bi bi-layout-text-window-reverse'

    },
    {
        id: 3,
        label: 'Admin',
        isTitle: true
    },
    {
        id: 4,
        label: 'Users',
        icon: 'bi bi-person-rolodex',
        link: '/users',
    },
    {
        id: 5,
        label: 'Settings',
        link: '/settings',
        icon: 'bi bi-gear'
    },
]
