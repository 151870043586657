import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByIndex'
}) export class SortByIndex implements PipeTransform {

	transform(list: any[]): any[] {

		list.sort((a: any, b: any) => {
			if (a.value.index < b.value.index) {
  			return -1;
			} else if (a.value.index > b.value.index) {
  			return 1;
			} else {
  			return 0;
			}
  	});

  	return list;
  }
}
