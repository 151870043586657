<router-outlet></router-outlet>

<div *ngIf="siteLoading" class="loader">
  <div class="loader-container">
	<div class="spinner-border text-secondary m-5" role="status">
	  <span class="visually-hidden">Loading...</span>
	</div>
  </div>
</div>

