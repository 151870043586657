import { Component, OnInit } from '@angular/core';
import { LoadingService } from '@app/core/services/loading.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'CUFI Admin Template';
  siteLoading: boolean = false;

  constructor(
    public loadingService: LoadingService
  ) {}

  ngOnInit() {
    this.loadingService.isLoading$.subscribe(async loading => {
      setTimeout(() => {
        this.siteLoading = loading;
      }, 0);
    });
  }
}
