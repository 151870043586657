<header id="page-topbar" (window:scroll)="windowScroll()">
  <div class="layout-width">
    <div class="navbar-header">
      <div class="d-flex">
        <div class="navbar-brand-box horizontal-logo">
          <a routerLink="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="assets/images/cufi-seal.svg" alt="" height="30">
            </span>

            <span class="logo-lg">
              <img src="assets/images/cufi-dark.svg" alt="" height="30">
            </span>
          </a>

          <a routerLink="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="assets/images/cufi-seal.svg" alt="" height="30">
            </span>
            <span class="logo-lg">
              <img src="assets/images/cufi-light.svg" alt="" height="45">
            </span>
          </a>
        </div>

        <button type="button" class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger shadow-none" id="topnav-hamburger-icon" (click)="toggleMobileMenu($event)">
          <span class="hamburger-icon">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </button>
      </div>

      <div class="d-flex align-items-center">
        <div dropdown class="dropdown ms-sm-3 header-item topbar-user">
          <button dropdownToggle type="button" class="btn shadow-none" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="d-flex align-items-center">
              <i class="ri-account-circle-fill fs-5xl"></i>
              <span class="text-start ms-xl-2">
                <span class="d-none d-xl-inline-block ms-1 fw-medium user-name-text">John Smith</span>
                <span class="d-none d-xl-block ms-1 fs-sm user-name-sub-text">Manager</span>
              </span>
            </span>
          </button>

          <div *dropdownMenu class="dropdown-menu dropdown-menu-end">
            <h6 class="dropdown-header">Welcome John!</h6>
            <a class="dropdown-item" routerLink="/"><i class="mdi mdi-account-circle text-muted fs-lg align-middle me-1"></i> <span class="align-middle"> Profile</span></a>
            <a class="dropdown-item" routerLink="/"><i class="mdi mdi-lifebuoy text-muted fs-lg align-middle me-1"></i> <span class="align-middle"> Help</span></a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" routerLink="/"><span class="badge bg-success-subtle text-success mt-1 float-end">New</span><i class="mdi mdi-cog-outline text-muted fs-lg align-middle me-1"></i> <span class="align-middle"> Settings</span></a>
            <a class="dropdown-item" href="javascript: void(0);" (click)="logout()"><i class="mdi mdi-logout text-muted fs-lg align-middle me-1"></i> <span class="align-middle" data-key="t-logout"> Logout</span></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

