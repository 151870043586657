import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class CrudService {
  constructor(private http: HttpClient) {}

  /***
   * Get
   */
  fetchData(url: any): Observable<any[]> {
    return this.http.get<any[]>(url);
  }

  addData(url: any, newData: any): Observable<any[]> {
    return this.http.post<any[]>(url, newData);
  }

  updateData(url: any, updatedData: any): Observable<any[]> {
    return this.http.put<any[]>(url, updatedData);
  }

  deleteData(url: any): Observable<void> {
    return this.http.delete<void>(url);
  }
}
