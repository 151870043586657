<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="text-sm-end d-none d-sm-block">
          &copy; {{year}} | Built by <a href="https://ynetconsulting.com" class="link-secondary">YNET</a>
        </div>
      </div>
    </div>
  </div>
</footer>
