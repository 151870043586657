<div class="app-menu navbar-menu">
  <div class="navbar-brand-box">
    <a routerLink="/" class="logo logo-dark">
      <span class="logo-sm">
        <img src="assets/images/cufi-seal.svg" alt="" height="30">
      </span>
      <span class="logo-lg">
        <img src="assets/images/cufi-dark.svg" alt="" height="30">
      </span>
    </a>

    <a routerLink="/" class="logo logo-light">
      <span class="logo-sm">
        <img src="assets/images/cufi-seal.svg" alt="" height="30">
      </span>
      <span class="logo-lg">
        <img src="assets/images/cufi-light.svg" alt="" height="45">
      </span>
    </a>

    <button type="button" class="btn btn-sm p-0 fs-3xl header-item float-end btn-vertical-sm-hover" id="vertical-hover" (click)="toggleMobileMenu($event)">
      <i class="ri-record-circle-line"></i>
    </button>
  </div>

  <ngx-simplebar id="scrollbar" style="height: 100%;" data-simplebar-track="light" class="h-100 simplebar-scrollable-y">
    <div class="container-fluid">
      <div id="two-column-menu"></div>

      <ul class="navbar-nav" id="navbar-nav">
        @for (item of menuItems; track $index) {
          @if (item.isTitle) {
            <li class="menu-title"><span>{{ item.label  }}</span></li>
          } @else {
            <li class="nav-item">
              @if (hasItems(item)) {
                <a href="javascript:void(0);" class="nav-link menu-link" data-bs-toggle="collapse" [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}" aria-expanded="false" (click)="toggleItem($event)">
                  <i class="{{ item.icon }}"></i>
                  <span> {{ item.label  }}</span>
                  @if (item.badge) {
                    <span class="badge badge-pill bg-danger">{{item.badge }}</span>
                  }
                </a>

                <div class="collapse menu-dropdown mega-dropdown-menu">
                  <ul class="nav nav-sm flex-column" aria-expanded="false">
                    @for (subitem of item.subItems; track $index) {
                      <li class="nav-item">
                        @if (hasItems(subitem)) {
                          <a class="nav-link" href="javascript:void(0);" [attr.data-parent]="subitem.parentId" data-bs-toggle="collapse" aria-expanded="false" (click)="toggleSubItem($event)">
                            {{ subitem.label }}
                          </a>

                          <div class="collapse menu-dropdown sub-menu">
                            <ul class="nav nav-sm flex-column" aria-expanded="false">
                              @for (subSubitem of subitem.subItems; track $index) {
                                <li class="nav-item">
                                  @if (hasItems(subSubitem)) {
                                    <a class="nav-link" href="javascript:void(0);" [attr.data-parent]="subSubitem.parentId" data-bs-toggle="collapse" aria-expanded="false" (click)="toggleExtraSubItem($event)">
                                      {{ subSubitem.label }}
                                    </a>

                                    <div class="collapse menu-dropdown extra-sub-menu">
                                      <ul class="nav nav-sm flex-column" aria-expanded="false">
                                        @for (subSubitem1 of subSubitem.subItems; track $index) {
                                          <li class="nav-item">
                                            <a [attr.data-parent]="subSubitem1.parentId" [routerLink]="subSubitem1.link" class="nav-link" (click)="updateActive($event)">
                                              {{ subSubitem1.label  }}
                                            </a>
                                          </li>
                                        }
                                      </ul>
                                    </div>
                                  } @else {
                                    <a [routerLink]="subSubitem.link" class="nav-link" [attr.data-parent]="subSubitem.parentId" (click)="updateActive($event)" aria-expanded="false">
                                      {{ subSubitem.label }}
                                    </a>
                                  }
                                </li>
                              }
                            </ul>
                          </div>
                        } @else {
                          <a [routerLink]="subitem.link" class="nav-link" [attr.data-parent]="subitem.parentId" (click)="updateActive($event)">
                            {{ subitem.label }}
                          </a>
                        }
                      </li>
                    }
                  </ul>
                </div>
              } @else {
                <a [routerLink]="item.link" class="side-nav-link-ref nav-link menu-link" (click)="toggleParentItem($event)">
                  @if (item.icon) {
                    <i class="bx {{ item.icon }} icon nav-icon" class=""></i>
                  }
                  <span class=""> {{ item.label  }} </span>
                  <span class="badge badge-pill bg-secondary">{{item.badge }}</span>
                </a>
              }
            </li>
          }
        }
      </ul>
    </div>
  </ngx-simplebar>

  <div class="sidebar-background"></div>
</div>


<div class="vertical-overlay" (click)="SidebarHide()"></div>
