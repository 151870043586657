<section class="auth-page-wrapper position-relative d-flex align-items-center justify-content-center min-vh-100">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-md-8 col-lg-6">
				<div class="card mb-0 py-4">
					<div class="card-body">
						<router-outlet></router-outlet>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
