import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { LoadingService } from '@app/core/services/loading.service';


@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

	constructor(private loader: LoadingService){}

  handleError(error: any): void {
    this.loader.stop();
    console.error(error);
    alert(error);
  }
}
