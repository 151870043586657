import { Injectable, OnInit } from "@angular/core";
import { Subject, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoadingService {
  public isLoading$ = new BehaviorSubject<boolean>(false);

  start() {
    this.isLoading$.next(true);
  }

  stop() {
    setTimeout(() => {
      this.isLoading$.next(false);
    }, 200); // prevents flashing
  }
}
