import { Injectable } from "@angular/core";
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";

// Auth Services
import { AuthenticationService } from "../services/auth.service";
import { AuthfakeauthenticationService } from "../services/authfake.service";
import { EnvironmentService } from "@environments/environment.service";
import { tap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class AuthGuard {
  private defaultAuth = "";

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
    private environmentService: EnvironmentService,
  ) {
    // Get Default AUTH
    this.environmentService.environment
      .pipe(tap((env) => (this.defaultAuth = env.defaultAuth)))
      .subscribe();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.defaultAuth === "firebase") {
      const currentUser = this.authenticationService.currentUser();
      if (currentUser) {
        // logged in so return true
        return true;
      }
    } else {
      const currentUser = this.authFackservice.currentUserValue;
      if (currentUser) {
        // logged in so return true
        return true;
      }
      // check if user data is in storage is logged in via API.
      if (localStorage.getItem("currentUser")) {
        return true;
      }
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(["/auth/login"], {
      queryParams: { returnUrl: state.url },
    });
    return false;
  }
}
