<div class="row">
  <div class="col-12">
    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
      <h4 class="mb-sm-0">{{title}}</h4>

      <div class="page-title-right">
        <ol class="breadcrumb m-0">
          @for (item of breadcrumbItems; track $index) {
            <li class="breadcrumb-item active">{{ item.label }}</li>
          }
        </ol>
      </div>
    </div>
  </div>
</div>
